import axios from "axios";

// const BASE_URL = "http://127.0.0.1:8000/api";

const BASE_URL = "https://api-vacosta-6sf6b-siqw5.ondigitalocean.app/api";
const getTypes = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getTypes`, {
      headers: { Accept: "application/json" },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getRootCategories = async (typeId) => {
  try {
    const response = await axios.get(`${BASE_URL}/getCategory/${typeId}`, {
      headers: { Accept: "application/json" },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getSubCategories = async (rootCategoryId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getSubCategory/${rootCategoryId}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getSizes = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getSizes`, {
      headers: { "Content-Type": "application/json" },
    });
    // console.log(response.data);
    return response.data;
    // [{size_id:1,label:"XL"}]
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getColors = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getColors`, {
      headers: { "Content-Type": "application/json" },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getNecklines = async () => {
  try {
    //   const response = await axios.get(`${BASE_URL}/neckline`, {
    //     headers: { "Content-Type": "application/json" },
    //   });
    //   console.log(response.data);
    //   return response.data;
    // [{neckline_id:1,neckline_name:"Color"}]
    return {
      data: [
        { neckline_id: 1, neckline_name: "Assymetrical Neckline" },
        { neckline_id: 2, neckline_name: "Boatneck Neckline" },
        { neckline_id: 3, neckline_name: "Collared Neckline" },
        { neckline_id: 4, neckline_name: "Cowl Neck Neckline" },
        { neckline_id: 5, neckline_name: "Crew Neck Neckline" },
        { neckline_id: 6, neckline_name: "Halter Neckline" },
        { neckline_id: 7, neckline_name: "Illusion Neckline" },
        { neckline_id: 8, neckline_name: "Jewel Neckline" },
        { neckline_id: 9, neckline_name: "Keyhole Neckline" },
        { neckline_id: 10, neckline_name: "Off Shoulder Neckline" },
        { neckline_id: 11, neckline_name: "Plunging Neckline" },
        { neckline_id: 12, neckline_name: "Queen Anne Neckline" },
        { neckline_id: 13, neckline_name: "Scallop Neckline" },
        { neckline_id: 14, neckline_name: "Scoop Neckline" },
        { neckline_id: 15, neckline_name: "Semi-Sweetheart Neckline" },
        { neckline_id: 16, neckline_name: "Spaghetti Strap Neckline" },
        { neckline_id: 17, neckline_name: "Square Neckline" },
        { neckline_id: 18, neckline_name: "Strapless Neckline" },
        { neckline_id: 19, neckline_name: "Strapless Sweetheart Neckline" },
        { neckline_id: 20, neckline_name: "Surplice Neckline" },
        { neckline_id: 21, neckline_name: "Sweetheart Neckline" },
        { neckline_id: 22, neckline_name: "Turtleneck Neckline" },
        { neckline_id: 23, neckline_name: "V-Neck Neckline" },
      ],
    };
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getSleeveLengths = async () => {
  try {
    //   const response = await axios.get(`${BASE_URL}/sleeveLength`, {
    //     headers: { "Content-Type": "application/json" },
    //   });
    //   console.log(response.data);
    //   return response.data;
    // [{sleeve_id:1,sleeve_name:"Color"}]
    return {
      data: [
        { sleeve_id: 1, sleeve_name: "Angel Sleeve" },
        { sleeve_id: 2, sleeve_name: "Batwing Sleeve" },
        { sleeve_id: 3, sleeve_name: "Bell Sleeve" },
        { sleeve_id: 4, sleeve_name: "Bishop Sleeve" },
        { sleeve_id: 5, sleeve_name: "Cap Sleeve" },
        { sleeve_id: 6, sleeve_name: "Cape Sleeve" },
        { sleeve_id: 7, sleeve_name: "Cold Shoulder Sleeve" },
        { sleeve_id: 8, sleeve_name: "Circular Sleeve" },
        { sleeve_id: 9, sleeve_name: "Cuff Sleeve" },
        { sleeve_id: 10, sleeve_name: "Drop Shoulder" },
        { sleeve_id: 11, sleeve_name: "Flutter Sleeve" },
        { sleeve_id: 12, sleeve_name: "Frill Sleeve" },
        { sleeve_id: 13, sleeve_name: "Juliet Sleeve" },
        { sleeve_id: 14, sleeve_name: "Kimono Sleeve" },
        { sleeve_id: 15, sleeve_name: "Lantern Sleeve" },
        { sleeve_id: 16, sleeve_name: "Long Sleeve" },
        { sleeve_id: 17, sleeve_name: "Mutton Leg Sleeve" },
        { sleeve_id: 18, sleeve_name: "Off Shoulder Sleeve" },
        { sleeve_id: 19, sleeve_name: "Open Sleeve" },
        { sleeve_id: 20, sleeve_name: "Peasant Sleeve" },
        { sleeve_id: 21, sleeve_name: "Petal Sleeve" },
        { sleeve_id: 22, sleeve_name: "Puffed Sleeve" },
        { sleeve_id: 23, sleeve_name: "Raglan Sleeve" },
        { sleeve_id: 24, sleeve_name: "Short Sleeve" },
        { sleeve_id: 25, sleeve_name: "Slashed Sleeve" },
        { sleeve_id: 26, sleeve_name: "Sleeveless" },
        { sleeve_id: 27, sleeve_name: "Sheer Sleeve" },
        { sleeve_id: 28, sleeve_name: "Three-Quarter Lenth Sleeve" },
        { sleeve_id: 29, sleeve_name: "Voluminous Billowy Sleeve" },
      ],
    };
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getSeasons = async () => {
  try {
    //   const response = await axios.get(`${BASE_URL}/season`, {
    //     headers: { "Content-Type": "application/json" },
    //   });
    //   console.log(response.data);
    //   return response.data;
    // [{season_id:1,season_name:"Color"}]
    return {
      data: [
        { season_id: 1, season_name: "Spring" },
        { season_id: 2, season_name: "Summer" },
        { season_id: 3, season_name: "Fall" },
        { season_id: 4, season_name: "Winter" },
      ],
    };
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const getAllCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getAllCategories`, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const getSections = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getSections`, {
      // headers: { "Content-Type": "application/json" },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
export {
  getTypes,
  getRootCategories,
  getSubCategories,
  getSizes,
  getColors,
  getSleeveLengths,
  getNecklines,
  getSeasons,
  getAllCategories,
  getSections,
};
