import axios from "axios";
import { searchQuery } from "../utils.js";
// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL = "https://api-vacosta-6sf6b-siqw5.ondigitalocean.app/api";
const getFilteredProducts = async (
  categories,
  stock,
  sort,
  price,
  page_nb,
  category_id
) => {
  const filtered_categories = JSON.stringify(categories);
  const filtered_sort = sort;
  const filtered_price = JSON.stringify(price);
  const filtered_stock = JSON.stringify(stock);

  const response = await axios.get(
    `${BASE_URL}/filteredProducts?page=${page_nb}&category=${category_id}&sort=${filtered_sort}&price=${filtered_price}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

const getFilteredCategories = async (
  categories,
  stock,
  sort,
  price,
  page_nb,
  category_id
) => {
  const filtered_categories = JSON.stringify(categories);
  const filtered_sort = sort;
  const filtered_price = JSON.stringify(price);
  const filtered_stock = JSON.stringify(stock);

  const response = await axios.get(
    `${BASE_URL}/filteredCategories?page=${page_nb}&category=${category_id}&sort=${filtered_sort}&price=${filtered_price}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};
const getSubCategories = async (category_id) => {
  const response = await axios.get(
    `${BASE_URL}/products?category=${category_id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  // console.log(response.data.categories);
  return response.data.categories;
};

const getQuantityByID = async (variant_id) => {
  const request = {
    variant_id: variant_id,
  };

  const response = await axios.post(`${BASE_URL}/qty`, request, {
    headers: { "Content-Type": "application/json" },
  });

  return response.data[0];
};

const getAllVariantsByID = async (product_id) => {
  const response = await axios.get(
    `${BASE_URL}/getAllVariantsByID/${product_id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};

const getImgByVariant = async (productID, colorID) => {
  const response = await axios.get(
    `${BASE_URL}/getImageByVariant/${productID}/${colorID}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};

const getQtyByVariant = async (productID, sizeID, colorID) => {
  const response = await axios.get(
    `${BASE_URL}/getQuantityByVariant/${productID}/${sizeID}/${colorID}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};

const calculateCartPrice = async (cart) => {
  const JSON_cart = JSON.stringify(cart);
  // const formData = new FormData();
  // formData.append('cart', cart);
  const response = await axios.get(
    `${BASE_URL}/calculateCartPrice?cart=${JSON_cart}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

const getVariant = async (size_id, color_id, product_id) => {
  const response = await axios.get(
    `${BASE_URL}/getVariant?size=${size_id}&color=${color_id}&product=${product_id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};

const getCartQuantities = async (cart) => {
  const JSON_cart = JSON.stringify(cart);
  const response = await axios.get(
    `${BASE_URL}/getCartQuantities?cart=${JSON_cart}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response.data;
};

const getDaily = async () => {
  const response = await axios.get(`${BASE_URL}/getDaily`, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};
const getHomeNewest = async () => {
  const response = await axios.get(`${BASE_URL}/getHomeNewest`, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};
const getNewest = async (date) => {
  const response = await axios.get(`${BASE_URL}/getNewest?date=` + date, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};
const getCategories = async () => {
  const response = await axios.get(`${BASE_URL}/getCategories`, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};
const getBestSellers = async () => {
  const response = await axios.get(`${BASE_URL}/getBestSellers`, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

const search = async (query, section) => {
  const response = await searchQuery(
    `${BASE_URL}/search?query=${query}&section=${section}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return response;
};
export {
  getFilteredProducts,
  getSubCategories,
  getQuantityByID,
  getAllVariantsByID,
  getImgByVariant,
  getQtyByVariant,
  calculateCartPrice,
  getVariant,
  getCartQuantities,
  getDaily,
  getCategories,
  search,
  getBestSellers,
  getHomeNewest,
  getNewest,
  getFilteredCategories,
};
