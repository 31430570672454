import axios from "axios";

// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL = "https://api-vacosta-6sf6b-siqw5.ondigitalocean.app/api";
const loginUser = async (email, password) => {
  // axios.defaults.withCredentials = true;
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await axios.post(`${BASE_URL}/auth/login`, formData, {
      headers: { "Content-Type": "application/json" },
    });
    // const response = await axios.get(`http://127.0.0.1:8000/sanctum/csrf-cookie`).then(response => {
    //   console.warn(response);
    // });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
    // console.log(error);
  }
};
const setCookie = async () => {
  await axios
    .get(`http://127.0.0.1:8000/sanctum/csrf-cookie`)
    .then((response) => {
      console.warn(response);
    });
};
const registerUser = async (firstname, lastname, email, phone, password) => {
  try {
    const formData = new FormData();
    formData.append("first_name", firstname);
    formData.append("last_name", lastname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("password_confirmation", password);
    const response = await axios.post(`${BASE_URL}/auth/register`, formData, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
    // console.log(error);
  }
};

const verifyAccount = async (email, verificationToken) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("verification_token", verificationToken);
    const response = await axios.post(
      `${BASE_URL}/auth/verifyAccount`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
    // console.log(error);
  }
};

const resetPassword = async (email, passwordResetToken, password) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password_reset_token", passwordResetToken);
    formData.append("password", password);
    formData.append("password_confirmation", password);
    const response = await axios.post(
      `${BASE_URL}/auth/resetPassword`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const sendPasswordResetLink = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await axios.post(
      `${BASE_URL}/auth/sendPasswordResetLink`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const sendVerificationLink = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await axios.post(
      `${BASE_URL}/auth/sendVerificationLink`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const logoutUser = async () => {
  const admin_token = sessionStorage.getItem("admin_token");
  if (admin_token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${admin_token}` };
  } else {
    const user_token = localStorage.getItem("vacosta_user_token");
    axios.defaults.headers.common = { Authorization: `Bearer ${user_token}` };
  }
  try {
    const response = await axios.post(`${BASE_URL}/auth/logout`);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
export {
  loginUser,
  registerUser,
  verifyAccount,
  resetPassword,
  sendPasswordResetLink,
  sendVerificationLink,
  setCookie,
  logoutUser,
};
